/* BASE STYLES */

body {
    margin: 0px !important;
    background-color: #fafafa;
}

a {
    font-family: 'Poppins';
}

label + .MuiInput-formControl {
    margin-top: 0px !important;
}

/* MATERIAL TABLE STYLES */
.table_custom {
    $this: &;

    font-family: 'Poppins' !important;
    padding-bottom: 4rem;
    .MuiPaper-root {
        box-shadow: none;
        background: #fafafa;
    }

    &.white .MuiPaper-root,
    &.white .MuiTableCell-root {
        background: white;
        background-color: white !important;
    }

    &.border {
        border: 1px solid #c7ccd1;
        border-radius: 8px;
        padding: 12px;
    }

    .MuiTableCell-root {
        border-bottom: 1px solid #c7ccd1;
        color: #454d54 !important;
        padding: 0px 8px;
        font-family: 'Poppins' !important;

        &:last-child {
            width: 64px !important;
        }

        .MuiButtonBase-root.MuiIconButton-root {
            height: 40px;
        }
    }

    &.noMenu {
        .MuiTableCell-root {
            min-height: 40px;
            max-height: 40px;
            height: 40px;

            &:last-child {
                width: auto !important;
            }
        }
    }

    .MuiTableHead-root div {
        color: #8f99a3;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.0015em;
        text-transform: uppercase;
    }

    tr.MuiTableRow-root.MuiTableRow-footer {
        & td:last-child {
            width: 100% !important;
        }
    }

    .MuiButtonBase-root div {
        text-transform: uppercase;
        font-weight: 300;
        color: #8f99a3;
        font-size: 14px;
        letter-spacing: 0.0015em;
    }
}

.tableBrands_custom {
    width: 100%;
    font-family: 'Poppins' !important;
    padding-bottom: 4rem;
    margin-top: 24px;
    border-spacing: 0px;
    overflow: auto;

    & thead {
        height: 24px;
        & th {
            padding: 0px 8px;
            border-bottom: 1px solid #c7ccd1;
            color: #8f99a3;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.0015em;
            text-transform: uppercase;
            text-align: left;
            vertical-align: middle;
            &:first-child {
                width: 32px;
            }
        }
    }

    & tbody {
        color: #454d54 !important;
        font-family: 'Poppins' !important;
        font-weight: 300;

        & tr {
            & td {
                padding: 0px 8px;
                border-bottom: 1px solid #c7ccd1;
                &:first-child svg {
                    opacity: 0;
                    fill: rgba(253, 114, 150, 1);
                }
                &:last-child {
                    width: 64px !important;
                }
            }
            &:hover {
                background-color: rgba(250, 236, 240, 1);
                transition: all 0.3s ease-in;
                & td {
                    border-bottom: 1px solid rgba(253, 114, 150, 1);
                    transition: all 0.3s ease-in;
                    &:first-child svg {
                        opacity: 1;
                        transition: all 0.3s ease-in;
                    }
                }
            }
        }

        .MuiButtonBase-root.MuiIconButton-root {
            height: 40px;
        }
    }

    &.noMenu {
        .MuiTableCell-root {
            min-height: 40px;
            max-height: 40px;
            height: 40px;

            &:last-child {
                width: auto !important;
            }
        }
    }

    tr.MuiTableRow-root.MuiTableRow-footer {
        & td:last-child {
            width: 100% !important;
        }
    }

    .MuiButtonBase-root div {
        text-transform: uppercase;
        font-weight: 300;
        color: #8f99a3;
        font-size: 14px;
        letter-spacing: 0.0015em;
    }

    .MuiInputBase-root {
    }
}

.table_analitics {
    $this: &;

    font-family: 'Poppins' !important;
    padding-bottom: 4rem;
    .MuiPaper-root {
        box-shadow: none;
        background: #fafafa;
    }

    &.white .MuiPaper-root,
    &.white .MuiTableCell-root {
        background: white;
        background-color: white !important;
    }

    &.border {
        border: 1px solid #c7ccd1;
        border-radius: 8px;
        padding: 12px;
    }

    .MuiTableCell-root {
        border-bottom: 1px solid #c7ccd1;
        color: #6f6f6f !important;
        padding: 0px 8px;
        font-family: 'Poppins' !important;

        &:nth-child(2) {
            width: 100% !important;
            min-width: 200px;
        }

        &:first-child {
            max-width: 30px !important;
        }
    }

    tr.MuiTableRow-root {
        & button {
            transition: 0.9s;
            display: none;
        }
        &:hover {
            transition: 0.3s;
            background-color: rgba(252, 62, 111, 0.1);
            & button {
                display: block;
            }
            & td {
                color: #393939 !important;
            }
        }
    }

    .MuiTableHead-root div {
        color: #8f99a3;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.0015em;
        text-transform: uppercase;
    }

    tr.MuiTableRow-root.MuiTableRow-footer {
        & button {
            display: block !important;
        }

        & td:last-child {
            width: 100% !important;
            max-width: 100% !important;
        }
        &:hover {
            background-color: #fafafa !important;
        }
    }

    // .MuiButtonBase-root div {
    //     text-transform: uppercase;
    //     font-weight: 300;
    //     color: #8f99a3;
    //     font-size: 14px;
    //     letter-spacing: 0.0015em;
    // }
}

.table_client {
    padding-bottom: 4rem;
    .MuiPaper-root {
        box-shadow: none;
        background: #fafafa;
    }

    .MuiTableCell-root {
        border-bottom: 1px solid #abb3ba;
        color: #5c6670 !important;
        padding: 0px 8px;

        &:last-child {
            width: 64px !important;
        }

        .MuiButtonBase-root.MuiIconButton-root {
            height: 40px;
        }
    }

    tr.MuiTableRow-root.MuiTableRow-footer {
        & td:last-child {
            width: 100% !important;
        }
    }

    .MuiButtonBase-root div {
        text-transform: uppercase;
        font-weight: 300;
        color: #8f99a3;
        font-size: 14px;
        letter-spacing: 0.0015em;
    }

    .MuiInputBase-root {
    }
}

/* DROPZONE STYLE */
.dzu-dropzone-custom {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 217px;
    max-height: 217px;
    overflow: auto;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 16px;
    margin-bottom: 16px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%238F99A3FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");

    &:hover {
        color: #5c6670 !important;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%235C6670FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e") !important;
    }

    &.dark {
        background-color: #5c6670;

        &:hover {
            background-color: #5c6670 !important;
        }
    }

    &.error {
        background-color: #fff0e5 !important;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23FF8833FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e") !important;
    }

    &::-webkit-scrollbar {
        background-color: #c7ccd1;
        border-radius: 4px;
        width: 8px;
        margin: 8px;
        padding-right: 10px;

        &-thumb {
            background-color: #73808c;
            border-radius: 4px;
        }
    }

    .dzu-previewContainer {
        padding: 16px;
        width: 400px;
        margin-top: 8px;
        height: 72px;
        background: #fafafa;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
        border-radius: 8px;

        &.dark {
            background-color: #454d54;

            &:hover {
                background-color: #5c6670 !important;
            }
        }
    }

    .dzu-inputlabel-custom {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        font-weight: 400;
        color: #8f99a3;
        cursor: pointer;
        font-size: 14px;
        text-align: center;
        padding: 0px 10px;
        font-family: 'Poppins';

        &:hover {
            color: #5c6670;
        }

        &.dark {
            &:hover {
                color: #8f99a3 !important;
            }
        }

        &.error {
            color: #ff8833 !important;
        }
    }
    .dzu-inputLabelWithFiles-custom {
        display: flex;
        font-weight: 400;
        color: #8f99a3;
        cursor: pointer;
        font-size: 14px;
        padding: 10px 16px;
        font-family: 'Poppins';
        border-radius: 8px;
        margin-top: 16px;
        margin-bottom: 24px;
        background-color: #fafafa;
        padding-bottom: 4px;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
        &.dark {
            background-color: #454d54;
        }

        &.error {
            color: #ff8833 !important;
        }
    }

    $this: &;

    &:hover {
        background: #fafafa;
        cursor: pointer;
    }

    /*&:before{
            content: "";
            position: absolute;
            border: 6px dashed #8F99A3;
            top: -5px;
            bottom: -5px;
            left: -5px;
            right: -5px;
        }*/
}

.dzu-previewContainer {
    border-bottom: none !important;
}

/* CARROUSEL */

.carousel .carousel-status {
    display: none;
}

// Grid, Button >>>Mobile
@media only screen and (max-width: 600px) {
    .MuiContainer-root {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
    .MuiIconButton-root {
        padding: 4px !important;
    }
    .MuiPaper-elevation1 {
        box-shadow: 1px 1px 5px 0px #0000003d !important;
    }
    .noPadding {
        padding: 0px !important;
    }
}

/* INPUT COLOR STYLES */

.input-color {
    height: 54px;
    margin-bottom: 16px;

    span {
        border-radius: 8px;
        width: 100%;
        height: 100%;
        border-color: #c7ccd1;
    }
}
