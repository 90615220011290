.heartContainer span {
    display: flex;
    align-items: inherit;
    height: 24px;
    width: 24px;
    position: relative;
    justify-content: inherit;
}

.heart {
    cursor: pointer;
    height: 64px;
    width: 64px;
    background-position: left;
    background-repeat: no-repeat;
    background-image: url('../../../assets/Images/fav.png');
    background-size: 2900%;
    animation: none;
    &:hover {
        background-color: none;
    }
}

.heartAbsolute {
    cursor: pointer;
    height: 64px;
    width: 64px;
    position: absolute;
    background-position: left;
    background-repeat: no-repeat;
    background-image: url('../../../assets/Images/fav.png');
    background-size: 2900%;
    &:hover {
        background-color: none;
    }
}

.is_active {
    background-position: right;
}

.is_animating {
    animation: heart-burst 0.8s steps(28) 1;
}

@keyframes heart-burst {
    from {
        background-position: left;
    }

    to {
        background-position: right;
    }
}
