@media only screen and (min-width: 601px) {
    body #live-chat-widget {
        bottom: -7px;
        right: -1px;
        z-index: 1200;
    }
}

@media only screen and (max-width: 600px) {
    body #live-chat-widget {
        display: none;
    }
    .MuiContainer-root {
        max-width: 100vw;
    }
}
